import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import {
  Box, Drawer, Paper, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Chip,
  TextField, Typography, CircularProgress, Button, Snackbar, Alert, Checkbox, ListItemText, Card, CardContent, Pagination, useMediaQuery, useTheme, IconButton
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import deviceStore from '../stores/DeviceStore';
import Papa from 'papaparse';

const LogsComponent = observer(() => {
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [selectedValves, setSelectedValves] = useState([]);
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filter, setFilter] = useState('');
  const [selectedColumns, setSelectedColumns] = useState(['LinePressure', 'SetPressure', 'ResetPressure', 'IndicationPressure', 'HydraulicPressure']);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100); // Default page size
  const [totalPages, setTotalPages] = useState(0);
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isGridLoading, setIsGridLoading] = useState(false);
  const logDetailsFields = ['LinePressure', 'SetPressure', 'ResetPressure', 'IndicationPressure', 'HydraulicPressure'];

  const exportToCSV = () => {
    const csv = Papa.unparse(logs);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'logs.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    deviceStore.loadDevices();
  }, []);

  const loadLogs = useCallback(async () => {
    if (!selectedDeviceId) {
      setLogs([]);
      return;
    }

    setIsLoading(true);
    try {
      await deviceStore.loadDeviceLogs({
        deviceId: selectedDeviceId,
        startDate,
        endDate,
        page,
        limit: rowsPerPage,
        filter
      });

      const transformedLogs = deviceStore.deviceLogs
        .filter(log => selectedValves.length === 0 || selectedValves.includes(log.details.ActiveValve))
        .map(log => {
          const details = typeof log.details === 'string' ? JSON.parse(log.details) : log.details;
          return {
            ...details,
            _id: log._id,
            device: log.device,
            timestamp: new Date(log.timestamp).toLocaleString(),
            event: log.event,
            id: log._id
          };
        });

      setLogs(transformedLogs);
      setTotalPages(deviceStore.pagination.totalPages);
    } catch (error) {
      console.error('Failed to load logs:', error);
      setSnackbar({ open: true, message: 'Failed to load logs', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [selectedDeviceId, startDate, endDate, filter, selectedValves, page, rowsPerPage]);

  useEffect(() => {
    const intervalId = setInterval(loadLogs, 60000); // Auto-refresh logs every 60 seconds
    return () => clearInterval(intervalId);
  }, [loadLogs]);

  const handleDeviceChange = (event) => {
    setSelectedDeviceId(event.target.value);
    setPage(1);
    loadLogs();
  };

  const handleValveChange = (event) => {
    setSelectedValves(event.target.value);
    setPage(1);
    loadLogs();
  };

  const handleColumnChange = (event) => {
    const { value } = event.target;
    setSelectedColumns(typeof value === 'string' ? value.split(',') : value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    loadLogs();
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    loadLogs();
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'ActiveValve', headerName: 'Valve', width: 80 },
    { field: 'DateTime', headerName: 'Date Time', width: 130 },
    ...selectedColumns.map(column => ({
      field: column, headerName: column, width: 130
    }))
  ];

  return (
    <Box>
      {isMobile && (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
          <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
            <ArrowBackIosIcon sx={{fontSize:'20px'}} />
          </IconButton>
       </Box>
      )}
      <Card raised sx={{ width: isMobile ? '100%' : '80%', overflow: 'visible', mb: 2 }}>
        <CardContent>
          <Box display="flex">
            <Typography variant="h6">Unit Logs</Typography>
          </Box>
          {selectedValves.map(valve => (
            <Chip label={valve} key={valve} sx={{ margin: 1 }} />
          ))}
          {isLoading ? <CircularProgress /> : (
            <>
              <DataGridPro
                rows={logs.reverse()}
                columns={columns}
                pageSize={rowsPerPage}
                onPageSizeChange={handleRowsPerPageChange}
                loading={isGridLoading}
                getRowId={(row) => row._id}
                disableSelectionOnClick
                sx={{ height: 500, width: '100%', mb: 2 }}
                paginationMode="server"
                onPageChange={handlePageChange}
                rowCount={totalPages * rowsPerPage}
              />
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
              />
            </>
          )}
        </CardContent>
      </Card>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        anchor="right"
        sx={{
          width: drawerOpen ? 250 : 0,
          '& .MuiDrawer-paper': {
            width: 250,
            height: 'calc(100%-64px)',
            marginTop: '64px',
            boxSizing: 'border-box',
            overflowX: 'hidden',
            paddingTop: '16px'
          },
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <FormControl sx={{ m: 1 }}>
          <InputLabel>Select Unit</InputLabel>
          <Select
            value={selectedDeviceId}
            onChange={handleDeviceChange}
            input={<OutlinedInput label="Select Device" />}
          >
            {deviceStore.devices.map((device) => (
              <MenuItem key={device._id} value={device._id}>{device.deviceName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <InputLabel>Select Valves</InputLabel>
          <Select
            multiple
            value={selectedValves}
            onChange={handleValveChange}
            input={<OutlinedInput label="Select Valves" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {['V1', 'V2'].map(valve => (
              <MenuItem key={valve} value={valve}>
                <Checkbox checked={selectedValves.includes(valve)} />
                <ListItemText primary={valve} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
          sx={{ m: 1 }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={e => setEndDate(e.target.value)}
          sx={{ m: 1 }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Filter"
          value={filter}
          onChange={e => setFilter(e.target.value)}
          placeholder='e.g., {"LinePressure":{"$gt":20}}'
          sx={{ m: 1 }}
        />
        <FormControl sx={{ m: 1 }}>
          <InputLabel>Log Details Fields</InputLabel>
          <Select
            multiple
            value={selectedColumns}
            onChange={handleColumnChange}
            input={<OutlinedInput label="Log Details Fields" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {logDetailsFields.map(field => (
              <MenuItem key={field} value={field}>
                <Checkbox checked={selectedColumns.includes(field)} />
                <ListItemText primary={field} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          sx={{ m: 2 }}
          onClick={loadLogs}
        >
          Apply Filters
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ m: 2 }}
          onClick={exportToCSV}
        >
          Export to CSV
        </Button>
      </Drawer>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default LogsComponent;
