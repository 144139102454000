import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Help = () => {
  const faqs = [
    { question: "How do I reset my password?", answer: "To reset your password, go to the settings page and follow the password reset instructions." },
    { question: "How can I contact customer support?", answer: "You can contact customer support via email at support@kratesoft.com or by calling our helpline." },
    { question: "How do I update my profile information?", answer: "To update your profile information, navigate to your profile page and click on the 'Edit Profile' button." },
    { question: "How does unit connectivity work?", answer: "Units can only be seen when they are connected. Real-time information is only available for connected units." },
    { question: "Will my unit history contain gaps?", answer: "Yes, if a unit is not connected, the history will contain gaps for the periods when the unit was disconnected." },
    { question: "How does the map display units?", answer: "The map will show all units that have at some point connected. Units will be green when active." },
    // Add more FAQs here
  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>Help & Support</Typography>

      <Typography variant="h5" sx={{ mb: 2 }}>Frequently Asked Questions</Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}

      <Button 
        variant="contained" 
        color="primary" 
        sx={{ mt: 4 }}
        onClick={() => window.location.href = '/contact-support'} // Replace with your actual support link
      >
        Get Support
      </Button>
    </Box>
  );
};

export default Help;
