import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Typography, IconButton, Box, Divider, List, ListItem, ListItemText, TextField, CircularProgress, Tooltip, Button, Paper, MenuItem, Select, FormControl, InputLabel, Snackbar, Alert, Grid,
  Autocomplete
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import LabelIcon from '@mui/icons-material/Label';
import deviceDetailStore from '../stores/DeviceDetailStore';
import flowStore from '../stores/FlowStore';
import authStore from '../stores/AuthStore';
import StageIndicator from './StageIndicator';
import organizationsStore from '../stores/OrganizationsStore';
import { useParams } from 'react-router';
import deviceStore from '../stores/DeviceStore';

const DeviceInfo = observer(({ onBack }) => {
  const [flowLogs, setFlowLogs] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editableFields, setEditableFields] = useState({});
  const [newNote, setNewNote] = useState('');
  const [importance, setImportance] = useState('normal');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [organizationOptions, setOrganizationsOptions] = useState([]);

  const { id } = useParams();

  const importanceLevels = [
    { value: 'low', label: 'Low', icon: <LowPriorityIcon /> },
    { value: 'normal', label: 'Normal', icon: <LabelIcon /> },
    { value: 'high', label: 'High', icon: <PriorityHighIcon /> },
  ];

  useEffect(() => {
    loadFlowData();
    fetchOrganizations();
  }, [deviceDetailStore.deviceInfo]);

  const handleEditClick = () => {
    setEditing(true);
  };

  const loadFlowData = async () => {
    const deviceId = deviceDetailStore.deviceInfo?._id;
    if (deviceId) {
      await flowStore.loadFlowLogs(deviceId);
      setFlowLogs(flowStore.flowLogs);
      setEditableFields({
        deviceName: deviceDetailStore.deviceInfo.deviceName,
        type: deviceDetailStore.deviceInfo.type || deviceDetailStore.deviceInfo.deviceData.type,
        region: deviceDetailStore.deviceInfo.region,
        customer: deviceDetailStore.deviceInfo.renter || null,
        ep: deviceDetailStore.deviceInfo.operator || null,
        status: deviceDetailStore.deviceInfo.status,
        deviceManufacturer: deviceDetailStore.deviceInfo.deviceManufacturer,
        notes: deviceDetailStore.deviceInfo.notes || [], // Initialize notes as an empty array if undefined
      });
    }
  };

  const fetchOrganizations = async () => {
    await organizationsStore.fetchOrganizations();
    setOrganizationsOptions(organizationsStore.organizations);
  };

  const handleSaveClick = async () => {
    const updatedDeviceInfo = {
      ...deviceDetailStore.deviceInfo,
      ...editableFields,
      renter: editableFields.customer?._id,
      operator: editableFields.ep?._id,
    };

    try {
      await deviceDetailStore.updateDeviceInfo(id, updatedDeviceInfo);
      setSnackbarMessage('Unit info saved successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving unit info:', error);
      setSnackbarMessage('Failed to save unit info');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      await loadFlowData();
      await deviceStore.loadDevices();
      setEditing(false);
    }

  };

  const handleChange = (field, value) => {
    setEditableFields({ ...editableFields, [field]: value });
  };

  const handleAddNote = async () => {
    if (newNote.trim() === '') {
      setSnackbarMessage('Note content cannot be empty');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const userId = authStore.userId;

    if (!userId) {
      console.error('User ID is not available');
      return;
    }

    const note = {
      content: newNote,
      createdBy: userId,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      importance,
    };

    try {
      await deviceDetailStore.addDeviceNote(id, note);
      setNewNote('');
      setImportance('normal');
      setSnackbarMessage('Note added successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error adding note:', error);
      setSnackbarMessage('Failed to add note');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }

  };

  const handleDeleteNote = async (noteId) => {
    try {
      await deviceDetailStore.deleteDeviceNote(id, noteId);
      setSnackbarMessage('Note deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting note:', error);
      setSnackbarMessage('Failed to delete note');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  const isAdmin = authStore.userRoles.includes('ADMIN');
  const isSuperAdmin = authStore.userRoles.includes('SUPER_ADMIN');

  const getFieldLabel = (field) => {
    if (field === 'ep') return 'E & P';
    if (field === 'customer') return 'Customer';
    if (field === 'deviceName') return 'Unit Name';
    if (field === 'deviceManufacturer') return 'Unit Manufacturer';
    return field.replace(/([A-Z])/g, ' $1').trim().replace(/\b\w/g, l => l.toUpperCase());
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Tooltip title="Back">
          <IconButton onClick={onBack} color="primary">
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <Box>
          {editing ? (
            <Box>
              <Button onClick={() => setEditing(false)} color="primary" sx={{ mr: 2 }}>Cancel</Button>
              <Button onClick={handleSaveClick} color="primary" sx={{ mr: 2 }} startIcon={<SaveIcon />}>
                Save
              </Button>
            </Box>
          ) : (
            <Button onClick={handleEditClick} color="primary" sx={{ mr: 2 }} startIcon={<EditIcon />}>
              Edit
            </Button>
          )}
        </Box>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box sx={{ mb: 2 }}>
          <StageIndicator stages={flowLogs} />
        </Box>
        {deviceDetailStore.deviceInfo ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={2}>
              {Object.keys(editableFields).map((field) => (
                field !== 'notes' && (
                  <Grid item xs={12} md={6} key={field}>

                    <ListItem sx={{ padding: 1 }}>
                      {field.toLowerCase() === 'customer' || field.toLowerCase() === 'ep' ? (
                        <ListItemText
                          primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold', textTransform: 'capitalize', color: 'text.primary' }}>{getFieldLabel(field)}</Typography>}
                          secondary={
                            editing ? (
                              <Autocomplete
                                options={organizationOptions}
                                getOptionLabel={(option) => option.name}
                                value={editableFields[field] || null}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    sx={{
                                      width: '100%',
                                      mt: 1,
                                      '& .MuiInputBase-root': {
                                        height: 40, // Adjust the height here
                                        padding: '2px 4px 7.5px 5px'
                                      },

                                    }}
                                  />}
                                renderOption={(props, option) => (
                                  <Box component="li" {...props} key={option._id} sx={{ display: 'flex', alignItems: 'center', padding: 1 }}>
                                    <Typography variant="body1">{option.name}</Typography>
                                  </Box>
                                )}
                                sx={{ width: '100%', height: 20 }}
                                onChange={(e, value) => {
                                  setEditableFields({ ...editableFields, [field]: value });
                                }}
                              />
                            ) : (
                              <Typography variant="body1" sx={{ mt: 1, color: 'text.secondary' }}>{editableFields[field]?.name || 'N/A'}</Typography>
                            )
                          }
                        >
                        </ListItemText>
                      ) : field === 'status' ? (
                        <ListItemText
                          primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold', textTransform: 'capitalize', color: 'text.primary' }}>{getFieldLabel(field)}</Typography>}
                          secondary={
                            editing ? (
                              <FormControl variant="outlined" size="small" sx={{ width: '100%', mt: 1 }}>
                                <InputLabel>Status</InputLabel>
                                <Select
                                  value={editableFields[field]}
                                  onChange={(e) => handleChange(field, e.target.value)}
                                  label="Status"
                                >
                                  <MenuItem value="connected">Connected</MenuItem>
                                  <MenuItem value="disconnected">Disconnected</MenuItem>
                                  <MenuItem value="maintenance">Maintenance</MenuItem>
                                </Select>
                              </FormControl>
                            ) : (
                              <Typography variant="body1" sx={{ mt: 1, color: 'text.secondary' }}>{editableFields[field]}</Typography>
                            )
                          }
                        />
                      ) : (
                        <ListItemText
                          primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold', textTransform: 'capitalize', color: 'text.primary' }}>{getFieldLabel(field)}</Typography>}
                          secondary={
                            editing ? (
                              <TextField
                                variant="outlined"
                                size="small"
                                value={editableFields[field]}
                                onChange={(e) => handleChange(field, e.target.value)}
                                fullWidth
                                sx={{ mt: 1 }}
                              />
                            ) : (
                              <Typography variant="body1" sx={{ mt: 1, color: 'text.secondary' }}>{editableFields[field]}</Typography>
                            )
                          }
                        />
                      )}
                    </ListItem>
                  </Grid>
                )
              ))}
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>Notes</Typography>
              <List>
                {editableFields.notes && editableFields.notes.length > 0 ? (
                  editableFields.notes.map((note, index) => (
                    <ListItem key={index} sx={{ display: 'flex', justifyContent: authStore.userId === note.createdBy?._id ? 'flex-end' : 'flex-start' }}>
                      <Paper sx={{ padding: 2, maxWidth: '70%', backgroundColor: authStore.userId === note.createdBy?._id ? 'primary.light' : 'grey.200' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', color: authStore.userId === note.createdBy?._id ? 'primary.text' : 'primary.dark' }}>{note.createdBy?.username}</Typography>
                        <Typography variant="body2" sx={{ color: authStore.userId === note.createdBy?._id ? 'primary.text' : 'primary.dark' }}>{note.content}</Typography>
                        <Typography variant="caption" sx={{ display: 'block', textAlign: 'right', color: authStore.userId === note.createdBy?._id ? 'primary.text' : 'primary.dark' }}>{new Date(note.createdAt).toLocaleString()}</Typography>
                        <Typography variant="caption" sx={{ display: 'block', textAlign: 'right', fontWeight: 'bold', color: authStore.userId === note.createdBy?._id ? 'primary.text' : 'primary.dark' }}>{note.importance.toUpperCase()}</Typography>
                        <IconButton color={authStore.userId === note.createdBy?._id ? 'primary.text': 'primary'} onClick={() => handleDeleteNote(note._id)}><DeleteIcon/></IconButton>
                      </Paper>
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>No notes available.</Typography>
                )}
              </List>
              <Box sx={{ display: 'flex', mt: 2, alignItems: 'center' }}>
                <TextField
                  variant="outlined"
                  size="small"
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                  fullWidth
                  placeholder="Add a note..."
                  sx={{ flexGrow: 1 }}
                />
                <FormControl variant="outlined" size="small" sx={{ ml: 1, minWidth: 120 }}>
                  <InputLabel>Importance</InputLabel>
                  <Select
                    value={importance}
                    onChange={(e) => setImportance(e.target.value)}
                    label="Importance"
                  >
                    {importanceLevels.map((level) => (
                      <MenuItem key={level.value} value={level.value}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {level.icon}
                          <Typography sx={{ ml: 1 }}>{level.label}</Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <IconButton color="primary" onClick={handleAddNote} sx={{ ml: 1 }}>
                  <SendIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <CircularProgress />
          </Box>
        )
        }
      </Box >
    </Box >
  );
});

export default DeviceInfo;
