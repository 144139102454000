import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, CardActionArea, Typography, Box, Paper, Card, CardContent, CardActions, Button, CardMedia, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import deviceStore from '../stores/DeviceStore';
import Rat from '../assets/rat.png';
import Bat from '../assets/bat.png';
import Default from '../assets/default.jpg';
import { useTheme } from '@mui/material';

const DeviceGrid = observer(() => {
  const navigate = useNavigate();

  const handleCardClick = (deviceId) => {
    navigate(`/unit/${deviceId}`);
  };

  const handleAddClick = () => {
    navigate('/edit-unit/new');
  }

  const getImageForDevice = (tags) => {
    if (!tags || tags.length === 0) return Default;
    if (tags.some(tag => tag.toLowerCase().includes('rat'))) return Rat;
    if (tags.some(tag => tag.toLowerCase().includes('bat'))) return Bat;
    return Default;
  };

  const theme = useTheme();

  return (
    <Box sx={{ p: 2, m: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', }}>
        <Button sx={{ mr: 3, mb: 3 }} variant="outlined" color="primary" onClick={handleAddClick}>
          <Typography variant="body1" sx={{ mr: 1 }}>Add Unit</Typography>
          <AddIcon />
        </Button>
      </Box>
      {deviceStore.isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : deviceStore.devices.length > 0 ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {deviceStore.devices.map((device) => (
            <Card key={device._id} sx={{ width: 300, transition: '0.3s', boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)', '&:hover': { boxShadow: `0 0 20px 5px ${theme.palette.secondary.main}` } }}>
              <CardActionArea onClick={() => handleCardClick(device._id)}>
                <CardMedia
                  component="img"
                  height="300"
                  image={getImageForDevice(device?.deviceData?.tags)}
                  alt="Device Image"
                />
                <CardContent>
                  <Typography variant="h6">{device?.deviceName || device?.deviceData?.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Status: {device?.deviceData?.connection_status}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Alerts: {device?.deviceData?.alerts}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Group: {device?.deviceData?.group}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
          <Typography variant="subtitle1">No units found</Typography>
          <Typography variant="body2" color="textSecondary">
            Add a new unit or adjust your filters.
          </Typography>
        </Box>
      )}
    </Box>
  );
});

export default DeviceGrid;
