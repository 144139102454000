import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';

const SummaryStatistics = ({ devices }) => {
  const theme = useTheme();

  const processDeviceStatus = () => {
    const statusCounts = { connected: 0, disconnected: 0, maintenance: 0 };
    if (devices) {
      devices.forEach(device => {
        const { connection_status, maintenance_mode } = device?.deviceData || {};
        if (maintenance_mode === 'on') {
          statusCounts.maintenance += 1;
        } else if (connection_status === 'connected') {
          statusCounts.connected += 1;
        } else {
          statusCounts.disconnected += 1;
        }
      });
    }
    return Object.entries(statusCounts).map(([name, count]) => ({ name, count }));
  };

  const processSignalStrength = () => {
    if (devices) {
      return devices.map(device => ({
        timestamp: new Date(device?.deviceData?.last_update).toLocaleDateString(),
        strength: device?.deviceData?.signal_strength
      })).sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    }
    return [];
  };

  const processHealthStatus = () => {
    const healthCounts = devices?.reduce((acc, device) => {
      const healthStatus = device?.deviceData?.health_status;
      if (healthStatus) {
        acc[healthStatus] = (acc[healthStatus] || 0) + 1;
      }
      return acc;
    }, {});
    return Object.entries(healthCounts || {}).map(([name, count]) => ({ name, count }));
  };

  // Processed data for charts
  const dataForStatus = processDeviceStatus();
  const dataForSignalStrength = processSignalStrength();
  const dataForHealthStatus = processHealthStatus();

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2, mt: 2 }}>
      <Box sx={{ flexGrow: 1, minWidth: 0 }}>
        <Typography variant="h6" gutterBottom>Unit Status</Typography>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={dataForStatus}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill={theme.palette.primary.main} />
          </BarChart>
        </ResponsiveContainer>
      </Box>

      <Box sx={{ flexGrow: 1, minWidth: 0 }}>
        <Typography variant="h6" gutterBottom>Signal Strength Over Time</Typography>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={dataForSignalStrength}>
            <XAxis dataKey="timestamp" />
            <YAxis domain={['dataMin - 10', 'dataMax + 10']} />
            <Tooltip />
            <Line type="monotone" dataKey="strength" stroke={theme.palette.secondary.main} />
          </LineChart>
        </ResponsiveContainer>
      </Box>

      <Box sx={{ flexGrow: 1, minWidth: 0 }}>
        <Typography variant="h6" gutterBottom>Health Status</Typography>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={dataForHealthStatus}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="count" fill={theme.palette.info.main} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default SummaryStatistics;
