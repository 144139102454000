import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Button, TextField, Paper, Box, Typography, FormControl,
  InputLabel, Select, MenuItem, Switch, FormControlLabel,
  Snackbar, OutlinedInput, Alert
} from '@mui/material';
import UsersStore from '../stores/UsersStore';
import RolesStore from '../stores/RolesStore';
import OrganizationsStore from '../stores/OrganizationsStore';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import authStore from '../stores/AuthStore';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const mfaTypes = ['NONE', 'SMS', 'APP'];

const UserForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [user, setUser] = useState({
    username: '',
    email: '',
    password: '',
    isActive: true,
    roles: [],
    organizations: [],
    mfaType: 'NONE',
    isTest: false,
    emailVerified: false,
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [newPassword, setNewPassword] = useState('');
  const [originalOrgs, setOriginalOrgs] = useState([]);
  const [newUser, setNewUser] = useState(false);

  useEffect(() => {
    const fetchRolesOrgs = async () => {
      await Promise.all([
        RolesStore.fetchRoles(),
        OrganizationsStore.fetchOrganizations()
      ]);
    };

    const fetchData = async () => {
      try {
        await fetchRolesOrgs();
        if (!/new/.test(location.pathname)) {
          const fetchedUser = await UsersStore.fetchUserById(id);
          if (fetchedUser) {
            setOriginalOrgs(fetchedUser.organizations.map(org => org._id) || []);
            setUser({
              ...fetchedUser,
              roles: fetchedUser.roles.map(role => role.name),
              organizations: fetchedUser.organizations.map(org => org.name)
            });
          } else {
            navigate('/users');
          }
        } else {
          setNewUser(true);
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        navigate('/users');
      }
    };

    fetchData();
  }, [id, navigate]);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setUser(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleMultiSelectChange = (name, value) => {
    setUser(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleResetEmailVerification = async () => {
    setUser(prev => ({
      ...prev,
      emailVerified: false,
    }));
    try {
      await UsersStore.updateUser(id, { emailVerified: false });
      await authStore.sendEmailVerification(user.email);
      setSnackbarMessage('Email verification reset');
      setSnackbarSeverity('info');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error sending email verification:', error);
      setSnackbarMessage('Failed to reset email verification');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteUser = async () => {
    try {
      await UsersStore.deleteUser(id);
      navigate('/users');
      setSnackbarMessage('User deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting user:', error);
      setSnackbarMessage('Failed to delete user');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSetPassword = async () => {
    try {
      await UsersStore.updateUserPassword(id, newPassword);
      setSnackbarMessage('Password updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setNewPassword(''); // Reset password field
    } catch (error) {
      console.error('Error updating password:', error);
      setSnackbarMessage('Failed to update password');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };


  const handleResetPassword = async () => {
    try {
      await UsersStore.resetPassword(user.email);
      setSnackbarMessage('Password reset email sent');
      setSnackbarSeverity('info');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error resetting password:', error);
      setSnackbarMessage('Failed to reset password');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleOneTimePassword = async () => {
    try {
      await UsersStore.sendOneTimePassword(id, authStore.email);
      setSnackbarMessage('One time password email sent');
      setSnackbarSeverity('info');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error sending one time password email:', error);
      setSnackbarMessage('Failed to send one time password email');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const selectedOrganizations = OrganizationsStore.organizations.filter(org => user.organizations.includes(org.name));
    try {
      let ownedByUsers = user.ownedBy?.user || [];
      if (!ownedByUsers.includes(authStore.userId)) {
        ownedByUsers.push(authStore.userId);
      };

      const ownedBy = {
        ...user.ownedBy,
        organizations: selectedOrganizations.map(org => org._id),
        user: ownedByUsers
      };
      if (newUser) {
        const data = {
          ...user,
          ownedBy,
          organizations: selectedOrganizations.map(org => org._id),
        };
        const response = await UsersStore.createUser(data);
        const createdUser = response.user;

        // add user to organizations
        selectedOrganizations.forEach(async org => {
          const data = { userId: createdUser._id }
          await OrganizationsStore.addMemberToOrganization(org._id, data);
        });
      } else {
        const data = {
          ...user,
          ownedBy,
          organizations: selectedOrganizations.map(org => org._id),
        }
        await UsersStore.updateUser(id, data);

        // check if orgs have changed
        const newOrgs = selectedOrganizations.map(org => org._id);
        const addedOrgs = newOrgs.filter(org => !originalOrgs.includes(org));
        const removedOrgs = originalOrgs.filter(org => !newOrgs.includes(org));

        if (addedOrgs.length > 0) {
          addedOrgs.forEach(async org => {
            const data = { userId: id }
            await OrganizationsStore.addMemberToOrganization(org, data);
          });
        }

        if (removedOrgs.length > 0) {
          removedOrgs.forEach(async org => {
            await OrganizationsStore.removeMemberFromOrganization(org, id);
          });
        }
      }
      navigate('/users');
    } catch (error) {
      console.error('Failed to submit user data:', error);
      setSnackbarMessage('Failed to submit user data');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, margin: {lg: 6}, flexGrow: 1 }}>
      <Typography variant="h6">{newUser ? 'Add New User' : 'Edit User'}</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Username"
          name="username"
          value={user.username}
          onChange={handleChange}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          type="email"
          value={user.email}
          onChange={handleChange}
          margin="normal"
          required
        />
        {newUser && (
          <TextField
            fullWidth
            label="Password"
            name="password"
            type="password"
            value={user.password}
            onChange={handleChange}
            margin="normal"
            required
          />
        )}
        <FormControl fullWidth margin="normal">
          <InputLabel>Roles</InputLabel>
          <Select
            multiple
            name="roles"
            value={user.roles}
            onChange={(e) => handleMultiSelectChange('roles', e.target.value)}
            input={<OutlinedInput label="Roles" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {RolesStore.roles.map(role => (
              <MenuItem key={role._id} value={role.name}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Organizations</InputLabel>
          <Select
            multiple
            name="organizations"
            value={user.organizations}
            onChange={(e) => handleMultiSelectChange('organizations', e.target.value)}
            input={<OutlinedInput label="Organizations" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {OrganizationsStore.organizations.map(org => (
              <MenuItem key={org._id} value={org.name}>
                {org.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {((authStore.userRoles.includes('SUPERADMIN') || authStore.userRoles.includes('ITADMIN')) && !newUser) ? (
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="h6">Password Management</Typography>
            <TextField
              fullWidth
              label="New Password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              margin="normal"
              helperText="Set a new password for this user"
            />
            <Button variant="contained" color="primary" onClick={handleSetPassword} sx={{ m: 1 }}>
              Set Password
            </Button>
            <Button variant="outlined" onClick={handleResetPassword} sx={{ m: 1 }}>
              Send Reset Email
            </Button>
            {/* below should be visible for plain admin as well */}
            <Button variant="outlined" onClick={handleOneTimePassword} sx={{ m: 1 }}>
              Send One Time Password Email
            </Button>

          </Box>
        ) : null}
        <FormControl fullWidth margin="normal">
          <InputLabel>MFA Type</InputLabel>
          <Select
            name="mfaType"
            value={user.mfaType}
            onChange={handleChange}
            input={<OutlinedInput label="MFA Type" />}
          >
            {mfaTypes.map(mfaType => (
              <MenuItem key={mfaType} value={mfaType}>
                {mfaType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 2 }}>
          <FormControlLabel
            control={<Switch checked={user.isActive} onChange={handleChange} name="isActive" />}
            label="Active"
          />
          <FormControlLabel
            control={<Switch checked={user.isTest} onChange={handleChange} name="isTest" />}
            label="Test User"
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1">
              {`Email is ${user.emailVerified ? 'verified' : 'not verified'}.`}
            </Typography>
            <Button onClick={handleResetEmailVerification} variant="outlined" size="small" sx={{ ml: 2 }}>
              Reset
            </Button>
          </Box>
          {newUser && authStore.userRoles.includes('SUPERADMIN') && (
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={handleDeleteUser}
              sx={{ mt: 2 }}
            >
              Delete User
            </Button>
          )}
          <Button variant="contained" type="submit" sx={{ mt: 2 }}>
            {newUser ? 'Create User' : 'Update User'}
          </Button>
          <Button variant="outlined" onClick={() => navigate('/users')} sx={{ mt: 2 }}>
            Cancel
          </Button>
        </Box>
      </form>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default UserForm;
