import { makeAutoObservable, runInAction } from "mobx";
import apiHandler from '../handlers/ApiHandler';

class DeviceDetailStore {
  deviceInfo = null;
  realTimeData = [];
  deviceLogs = [];
  deviceStatus = null;
  isLoading = false;
  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  async loadDeviceInfo(deviceId) {
    this.setLoading(true);
    try {
      const info = await apiHandler.get(`/devices/${deviceId}`);
      runInAction(() => {
        this.deviceInfo = info;
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }


  async loadRealTimeData(deviceId) {
    this.setLoading(true);
    try {
      const realTimeData = await apiHandler.get(`/devices/${deviceId}/real-time`);
      runInAction(() => {
        this.realTimeData = realTimeData;
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async loadDeviceLogs({ deviceId, startDate, endDate, page = 1, limit = 10, sortBy = '', fields = '', search = '', searchField = 'name' }) {
    this.setLoading(true);
    try {
      // Construct the filter object
      let filterObj = {};
      if (startDate || endDate) {
        filterObj.timestamp = {};
        if (startDate) filterObj.timestamp.$gte = new Date(startDate).toISOString();
        if (endDate) filterObj.timestamp.$lte = new Date(endDate).toISOString();
      }
      if (deviceId) filterObj.device = deviceId;

      // Convert the filter object to a JSON string
      const filter = JSON.stringify(filterObj);

      const queryParams = new URLSearchParams({ page, limit, sortBy, filter, fields, search, searchField });

      const response = await apiHandler.get(`/devices/logs?${queryParams.toString()}`);
      runInAction(() => {
        this.deviceLogs = response.results;
        this.pagination = {
          hasMore: response.hasMore,
          totalCount: response.totalCount,
          currentPage: response.currentPage,
          pageSize: response.pageSize,
          totalPages: response.totalPages
        };
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }


  async loadDeviceStatus(deviceId) {
    this.setLoading(true);
    try {
      const status = await apiHandler.get(`/devices/${deviceId}/status`);
      runInAction(() => {
        this.deviceStatus = status;
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async updateDeviceInfo(deviceId, deviceData) {
    this.setLoading(true);
    try {
      const response = await apiHandler.put(`/devices/${deviceId}`, deviceData);
      runInAction(() => {
        this.deviceInfo = response;
      });
      return response;
    } catch (error) {
      this.handleError(error);
      throw error;
    } finally {
      this.setLoading(false);
    }

  }

  async addDeviceNote(deviceId, noteData) {
    this.setLoading(true);
    try {
      const response = await apiHandler.post(`/devices/${deviceId}/notes`, noteData);
      runInAction(() => {
        this.deviceInfo = response;
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  };

  async updateDeviceNote(deviceId, noteId, noteData) {
    this.setLoading(true);
    try {
      const response = await apiHandler.put(`/devices/${deviceId}/notes/${noteId}`, noteData);
      runInAction(() => {
        this.deviceInfo = response;
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  };

  async deleteDeviceNote(deviceId, noteId) {
    this.setLoading(true);
    try {
      const response = await apiHandler.delete(`/devices/${deviceId}/notes/${noteId}`);
      runInAction(() => {
        this.deviceInfo = response;
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  
  }

  setLoading(loading) {
    this.isLoading = loading;
  }

  handleError(error) {
    runInAction(() => {
      this.error = error.message || 'An unexpected error occurred';
    });
    console.error('DeviceDetailStore Error:', this.error);
  }

  clearError() {
    this.error = null;
  }

  clearData() {
    runInAction(() => {
      this.deviceInfo = null;
      this.realTimeData = null;
      this.deviceLogs = [];
      this.deviceStatus = null;
    });
  }
}

const deviceDetailStore = new DeviceDetailStore();
export default deviceDetailStore;
