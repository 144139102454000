import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
    Box, IconButton, Typography, Button, ListItem, ListItemText, ListItemIcon, List,
    Checkbox, FormControl, InputLabel, MenuItem, OutlinedInput, Select, ToggleButton, ToggleButtonGroup,
    CircularProgress
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import TableViewIcon from '@mui/icons-material/TableView';
import ListViewIcon from '@mui/icons-material/ViewList';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import MailIcon from '@mui/icons-material/Mail';
import alertStore from '../stores/AlertStore';
import deviceStore from '../stores/DeviceStore';

import { DataGrid } from '@mui/x-data-grid';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AlertsTable = observer(() => {
  const [viewMode, setViewMode] = useState('list');
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState(['reminder', 'message', 'alert', 'important']);
  const [selectedSeverity, setSelectedSeverity] = useState(['low', 'medium', 'high']);
  const [myAlertsFilters, setMyAlertsFilters] = useState(null);
  const [alertView, setAlertView] = useState('all');

  useEffect(() => {
    alertStore.loadAlerts({ myAlertsFilters, alertView });
  }, [selectedOrgs, selectedDevices, selectedTypes, selectedSeverity, myAlertsFilters, alertView]);

    const columns = [
        { field: 'message', headerName: 'Message', width: 200 },
        { field: 'severity', headerName: 'Severity', width: 110 },
        { field: 'user', headerName: 'User', width: 150 },
        { field: 'device', headerName: 'Unit', width: 150 },
        { field: 'createdAt', headerName: 'Created At', width: 180, renderCell: (params) => new Date(params.value).toLocaleString() },
        { field: 'updatedAt', headerName: 'Updated At', width: 180, renderCell: (params) => new Date(params.value).toLocaleString()},
        { field: 'read', headerName: 'Read', width: 100 }
    ];

  const saveMyAlerts = () => {
    setMyAlertsFilters({
      orgs: selectedOrgs,
      devices: selectedDevices,
      types: selectedTypes,
      severity: selectedSeverity,
    });
  };

  const toggleViewMode = () => {
    setViewMode(viewMode === 'list' ? 'table' : 'list');
  };

  const markAsRead = async (alertId) => {
    await alertStore.markAlertAsRead(alertId);
    alertStore.loadAlerts({ myAlertsFilters, alertView });
  };

  const clearMyAlerts = () => {
    setMyAlertsFilters(null);
    setSelectedOrgs([]);
    setSelectedDevices([]);
    setSelectedTypes([]);
    setSelectedSeverity([]);
  };

  const getIcon = (severity) => {
    switch (severity) {
      case 'important':
        return <ErrorIcon />;
      case 'alert':
        return <WarningIcon />;
      case 'message':
        return <InfoIcon />;
      case 'reminder':
        return <MailIcon />;
      default:
        return <MailIcon />;
    }
  };

  const renderSubscriptionControls = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2, width: '100%' }}>
      <ToggleButtonGroup
        value={alertView}
        exclusive
        onChange={(event, newView) => setAlertView(newView)}
        aria-label="View selection"
      >
      </ToggleButtonGroup>
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 4}}>
        <FormControl sx={{ m: 1, width: 250 }}>
            <InputLabel id="device-select-label">Units</InputLabel>
            <Select
            labelId="device-select-label"
            multiple
            value={selectedDevices}
            onChange={(event) => setSelectedDevices(event.target.value)}
            input={<OutlinedInput label="Units" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            >
            {deviceStore.devices.map((device) => (
                <MenuItem key={device.id} value={device.id}>
                <Checkbox checked={selectedDevices.indexOf(device.id) > -1} />
                <ListItemText primary={device.name || 'Unknown Unit'} />
                </MenuItem>
            ))}
            </Select>
        </FormControl>


      {/* Repeat for other Select components */}
      <FormControl sx={{ m: 1, width: 250 }}>
        <InputLabel id="org-select-label">Organizations</InputLabel>
        <Select
          labelId="org-select-label"
          multiple
          value={selectedOrgs}
          onChange={(event) => setSelectedOrgs(event.target.value)}
          input={<OutlinedInput label="Organizations" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {/* Map through organizations */}
        </Select>
      </FormControl>
      </Box>
        <Button
            variant="contained"
            color="primary"
            onClick={saveMyAlerts}
            sx={{ mt: 3, maxWidth: 550 }}
        >
            Save as My Alerts
        </Button>
        <Button
            variant="outlined"
            color="secondary"
            onClick={clearMyAlerts}
            sx={{ mt: 3, maxWidth: 550 }}
        >
            Clear My Alerts
        </Button>
    </Box>
  );

  const renderAlertsTable = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton
                onClick={() => setViewMode('list')}
                color={viewMode === 'list' ? 'primary' : 'default'}
            >
                <ListViewIcon />
            </IconButton>
            <IconButton
                onClick={() => setViewMode('table')}
                color={viewMode === 'table' ? 'primary' : 'default'}
            >
                <TableViewIcon />
            </IconButton>
    </Box>

      {viewMode === 'list' ? (
        alertStore.alerts.length > 0 ? (
          <List>
            {alertStore.alerts.map((alert) => (
              <ListItem key={alert._id} sx={{ cursor: 'pointer', whiteSpace: 'pre-wrap' }} onClick={() => markAsRead(alert._id)}>
                <FiberManualRecordIcon
                    color={!alert.read ? "primary" : "transparent"}
                    sx={{
                        fontSize: 12,
                        marginRight: 2,
                        animation: 'fade-out 0.5s',
                        color: alert.read ? 'transparent' : undefined
                    }}
                />
                <ListItemIcon sx={{ minWidth: 40 }}>{getIcon(alert.severity)}</ListItemIcon>
                <ListItemText 
                  primary={alert.message || 'No Message'} 
                  secondary={`Severity: ${alert.severity || 'N/A'} - ${new Date(alert.timestamp).toLocaleString()}`} 
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography sx={{ textAlign: 'center' }}>No alerts found.</Typography>
        )
      ) : (
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={alertStore.alerts.map((alert, index) => ({ ...alert, id: index }))}
            columns={[
              { 
                field: 'readIndicator', 
                headerName: 'Read', 
                width: 100, 
                renderCell: (params) => (
                  params.row.read ? null : 
                  <FiberManualRecordIcon  color="primary" sx={{ fontSize: 12, marginLeft: 1, animation: 'fade-out 0.5s' }} />
                )
              },
              ...columns
            ]}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
            onRowClick={(params) => markAsRead(params.id)}
          />
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: {lg: 3} }}>
      {renderSubscriptionControls()}
      {renderAlertsTable()}
    </Box>
  );
});

export default AlertsTable;
