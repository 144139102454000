import React, { useState, useEffect } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Typography,
  CssBaseline,
  useMediaQuery,
  ListItemIcon,
} from '@mui/material';
import NotificationIcon from '@mui/icons-material/Notifications';
import { observer } from 'mobx-react';
import AlertsTable from '../components/AlertsTable';
import alertStore from '../stores/AlertStore';

const AlertsPage = observer(() => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const [selectedTab, setSelectedTab] = useState(1);
  const isSmallerScreen = useMediaQuery('(max-width:1200px)');

  useEffect(() => {
    alertStore.loadAlerts().catch((error) => {
      setSnackbarMessage(error.message || 'Failed to load alerts');
      setSeverity('error');
      setOpenSnackbar(true);
    });
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  return (
    <>
      <CssBaseline />
      <Box sx={{ display: 'flex' }}>
        <Drawer
          variant={isSmallerScreen ? 'temporary' : 'permanent'}
          anchor="left"
          sx={{
            width: 250,
            flexShrink: 0,
            left: 250,
            '& .MuiDrawer-paper': {
              width: 250,
              left: 250,
              boxSizing: 'border-box',
            },
          }}
        >
          <List>
            {['Notifications', 'Alerts', 'Messages', 'History', 'Scripts', 'Manage Alerts'].map((text, index) => (
              <ListItem button key={text} selected={selectedTab === index} onClick={() => handleTabChange(index)}>
                <ListItemIcon ><NotificationIcon /></ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ m: 3 }}>Alerts</Typography>
              <AlertsTable />
            </CardContent>
          </Card>
          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </>
  );
});

export default AlertsPage;
