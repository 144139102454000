import { makeAutoObservable, runInAction } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class OrganizationsStore {
  organizations = [];
  isLoading = false;
  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchOrganizations() {
    this.isLoading = true;
    this.error = null;
    try {
      const response = await apiHandler.get('/organizations');
      runInAction(() => {
        this.organizations = response;
      })
    } catch (error) {
      this.error = error.toString();
      console.error("Error fetching organizations:", error);
    } finally {
      this.isLoading = false;
    }
  }

  async createOrganization(organizationData) {
    try {
      const response = await apiHandler.post('/organizations', organizationData);
      this.organizations.push(response);
    } catch (error) {
      this.error = error.toString();
      console.error("Error creating organization:", error);
    }
  }

  async updateOrganization(organizationId, organizationData) {
    try {
      const response = await apiHandler.put(`/organizations/${organizationId}`, organizationData);
      const index = this.organizations.findIndex((org) => org._id === organizationId);
      if (index !== -1) {
        this.organizations[index] = response;
      }
    } catch (error) {
      this.error = error.toString();
      console.error(`Error updating organization with ID ${organizationId}:`, error);
    }
  }

  async deleteOrganization(organizationId) {
    try {
      await apiHandler.delete(`/organizations/${organizationId}`);
      this.organizations = this.organizations.filter((org) => org._id !== organizationId);
    } catch (error) {
      this.error = error.toString();
      console.error(`Error deleting organization with ID ${organizationId}:`, error);
    }
  }

  async addMemberToOrganization(organizationId, data) {
    try {
      await apiHandler.post(`/organizations/${organizationId}/members`, data);
    } catch (error) {
      this.error = error.toString();
      console.error(`Error adding member to organization with ID ${organizationId}:`, error);
    }
  };

  async removeMemberFromOrganization(organizationId, userId) {
    try {
      await apiHandler.delete(`/organizations/${organizationId}/members/${userId}`);
    } catch (error) {
      this.error = error.toString();
      console.error(`Error removing member from organization with ID ${organizationId}:`, error);
    }
  }

  getOrganizationById(organizationId) {
    return this.organizations.find((org) => org._id === organizationId);
  }

  clearError() {
    this.error = null;
  }
}

const organizationsStore = new OrganizationsStore();
export default organizationsStore;
