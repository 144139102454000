import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Typography, CircularProgress, Box, Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import deviceDetailStore from '../stores/DeviceDetailStore';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const MAX_POINTS = 100;

const RealTimeData = observer(() => {
  const [displayData, setDisplayData] = useState({ V1: [], V2: [] });
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isPlaybackMode, setIsPlaybackMode] = useState(false);
  const [lastFetchTime, setLastFetchTime] = useState(Date.now());

  useEffect(() => {
    const deviceId = deviceDetailStore.deviceInfo?._id;
    if (!deviceId) return;

    const fetchData = async () => {
      if (isLoadingData || (Date.now() - lastFetchTime < 60000 && deviceDetailStore.realTimeData.length > 0)) return;
      setIsLoadingData(true);
      await deviceDetailStore.loadRealTimeData(deviceId);
      setIsLoadingData(false);
      setLastFetchTime(Date.now());
      if (deviceDetailStore.realTimeData.length === 0) {
        setIsPlaybackMode(true);
      }
    };

    fetchData();
    const fetchDataInterval = setInterval(fetchData, 300000); // Fetch data every 5 minutes

    const plotInterval = setInterval(() => {
      if (deviceDetailStore.realTimeData.length > 0) {
        const nextData = deviceDetailStore.realTimeData.shift();
        setDisplayData(prevData => {
          const updatedV1 = nextData.ActiveValve === 'V1' ? [...prevData.V1, nextData] : prevData.V1;
          const updatedV2 = nextData.ActiveValve === 'V2' ? [...prevData.V2, nextData] : prevData.V2;
          return { V1: updatedV1.slice(-MAX_POINTS), V2: updatedV2.slice(-MAX_POINTS) };
        });
      } else if (!isPlaybackMode) {
        fetchData();
      }
    }, 1000);

    return () => {
      clearInterval(plotInterval);
      clearInterval(fetchDataInterval);
    };
  }, [deviceDetailStore, deviceDetailStore.deviceInfo, isLoadingData, isPlaybackMode, lastFetchTime]);

  const createLineChart = (data, title) => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="subtitle1">{title}</Typography>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <XAxis dataKey="DateTime" tickFormatter={(unixTime) => dayjs(unixTime).format('HH:mm:ss')} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="LinePressure" stroke="#8884d8" name="Line Pressure" dot={false} activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="SetPressure" stroke="#82ca9d" name="Set Pressure" dot={false} activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="IndicationPressure" stroke="#ff7300" name="Indication Pressure" dot={false} activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="HydraulicPressure" stroke="#387908" name="Hydraulic Pressure" dot={false} activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="SystemVoltage" stroke="#a83279" name="System Voltage" dot={false} activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );

  const latestDataV1 = displayData.V1[displayData.V1.length - 1];
  const latestDataV2 = displayData.V2[displayData.V2.length - 1];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
      <Typography variant="h6">Real-Time Data Summary</Typography>
      <Typography variant="body1">
        {latestDataV1 && `Valve V1 - Latest Line Pressure: ${latestDataV1.LinePressure}, System Voltage: ${latestDataV1.SystemVoltage}`}
      </Typography>
      <Typography variant="body1">
        {latestDataV2 && `Valve V2 - Latest Line Pressure: ${latestDataV2.LinePressure}, System Voltage: ${latestDataV2.SystemVoltage}`}
      </Typography>
      {isLoadingData ? <CircularProgress /> : null}
      {createLineChart(displayData.V1, "Valve V1 Data")}
      {createLineChart(displayData.V2, "Valve V2 Data")}
    </Box>
  );
});

export default RealTimeData;
