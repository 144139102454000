import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Box, Tab, Tabs, Typography, Snackbar, Alert, Paper, LinearProgress } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import RealtimeIcon from '@mui/icons-material/Speed';
import HistoryIcon from '@mui/icons-material/History';
import StatusIcon from '@mui/icons-material/BarChart';
import DeviceInfo from '../components/DeviceInfo';
import RealTimeData from '../components/RealTimeData';
import DeviceLogs from '../components/DeviceLogs';
import deviceDetailStore from '../stores/DeviceDetailStore';
import DeviceStages from '../components/DeviceStages';
import flowStore from '../stores/FlowStore';
import StageIndicator from '../components/StageIndicator';
import { useLocation, useNavigate } from 'react-router-dom';

const tabs = {
  INFO: "info",
  REAL_TIME: "real-time",
  LOGS: "logs",
  STAGES: "stages",
};

const DeviceDetails = observer(() => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(tabs.INFO);
  const [flowLogs, setFlowLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('info');

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      deviceDetailStore.loadDeviceInfo(id),
      flowStore.loadFlowLogs(id)
    ]).then(() => {
      setFlowLogs(flowStore.flowLogs);
    }).catch((error) => {
      setSnackbarMessage(error.message || 'Failed to load unit details');
      setSeverity('error');
      setOpenSnackbar(true);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [id]);

  useEffect(() => {
    const stage = query.get('stageId');
    if (stage) {
      setActiveTab(tabs.STAGES);
    }
  }, [location.search]);

  const handleTabChange = (_, newTab) => {
    setActiveTab(newTab);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleBack = () => {
    navigate(-1);
  }

  const handleStageClick = (stage) => {
    const queryParams = new URLSearchParams({ tab: 'analytics', stageId: stage.id });
    navigate(`/dashboard?${queryParams.toString()}`);
  };

  return (
    <>
      <Paper sx={{ m: {lg: 3}, p: 3 }}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="Unit Details Tabs" variant="fullWidth" indicatorColor="primary">
          <Tab icon={<InfoIcon />} label="Unit Info" value={tabs.INFO} />
          <Tab icon={<RealtimeIcon />} label="Real-Time Data" value={tabs.REAL_TIME} />
          <Tab icon={<HistoryIcon />} label="Logs" value={tabs.LOGS} />
          <Tab icon={<StatusIcon />} label="Stages" value={tabs.STAGES} />
        </Tabs>
        {activeTab === tabs.INFO && <DeviceInfo onBack={handleBack} />}
        {activeTab === tabs.REAL_TIME && <RealTimeData />}
        {activeTab === tabs.LOGS && <DeviceLogs />}
        {activeTab === tabs.STAGES && (
          <>
            <StageIndicator stages={flowLogs} />
            <DeviceStages flowLogs={flowLogs} isLoading={isLoading} handleStageClick={handleStageClick} />
          </>
        )}
        {deviceDetailStore.isLoading && <LinearProgress color="primary" />}
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
});

export default DeviceDetails;
