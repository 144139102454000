import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Typography, Box, Paper, Divider, TextField, Button} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import PeopleIcon from '@mui/icons-material/People';
import authStore from '../stores/AuthStore';
import apiHandler from '../handlers/ApiHandler';
import userPreferencesStore from '../stores/UserPreferencesStore';

const ProfilePage = observer(() => {
  // API Settings
  const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_API || '');
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(authStore.userId);
  const [token, setToken] = useState('');
  const [debugMode, setDebugMode] = useState(false);
  const [connectionSpeed, setConnectionSpeed] = useState('Not Available');
  const [currentIP, setCurrentIP] = useState('Not Available');
  const [apiIP, setApiIP] = useState('Not Available');

  // User Details
  const [username, setUsername] = useState(userPreferencesStore.username);
  const [displayName, setDisplayName] = useState(userPreferencesStore.displayName);
  const [email, setEmail] = useState(userPreferencesStore.email);
  const [mfaType, setMfaType] = useState(userPreferencesStore.mfaType);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  })

  const handleSaveUser = async () => {
    const updatedDetails = {
      username,
      displayName,
      email,
      mfaType
    };
    try {
      await userPreferencesStore.updateUserDetails(updatedDetails);
      setSnackbar({ open: true, message: 'User details updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Failed to update user details:', error);
      setSnackbar({ open: true, message: 'Failed to update user details', severity: 'error' });
    }
  };

  useEffect(() => {
    setUserId(authStore.userId);
  }, [authStore.userId]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await userPreferencesStore.loadUserDetails(userId);
        setUsername(userData.username);
        setDisplayName(userData.displayName);
        setEmail(userData.email);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  useEffect(() => {
    setApiUrl(authStore.apiUrl); // Replace with actual logic to get API URL from authStore
    setToken(authStore.token); // Replace with actual logic to get token from authStore
    setDebugMode(authStore.debugMode); // Replace with actual logic to get debug mode from authStore

    const fetchData = async () => {
      try {
        const pingResponse = await apiHandler.ping(authStore.apiUrl);
        const currentTime = new Date().getTime();
        setConnectionSpeed(`${currentTime - pingResponse.startTime} ms`);
      } catch (error) {
        setConnectionSpeed('Unavailable');
      }

      try {
        setCurrentIP(await apiHandler.getCurrentIP());
        setApiIP(await apiHandler.getApiIP());
      } catch (error) {
        setCurrentIP('Unavailable');
        setApiIP('Unavailable');
      }
    };

    fetchData();
  }, [authStore.apiUrl, authStore.token, authStore.debugMode, userPreferencesStore.language, userPreferencesStore.timeZone, userPreferencesStore.dateFormat, userPreferencesStore.username, userPreferencesStore.displayName, userPreferencesStore.email]);

  return (

    loading ? (
      <div>Loading...</div>
    ) : (
      <>
        <Paper elevation={3} sx={{ p: 2, m: {lg: 2}, mb: {xs: 2} }}>
          <Typography variant="h5" gutterBottom color="primary">
            Profile
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AccountCircleIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Username
            </Typography>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <PeopleIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Display Name
            </Typography>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <KeyIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Email
            </Typography>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', mt: 1 }}>
            <Button variant='contained' onClick={handleSaveUser}>Save</Button>
          </Box>
        </Paper>
    </>
    )
  );
});

export default ProfilePage;