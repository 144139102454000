import { makeAutoObservable, runInAction, toJS } from "mobx";
import apiHandler from '../handlers/ApiHandler';

class DeviceStore {
  devices = [];
  deviceLogs = [];
  isLoading = false;
  error = null;
  selectedDevice = null;
  pagination = {};

  constructor() {
    makeAutoObservable(this);
  }

  async loadDevices() {
    this.setLoading(true);
    try {
      const data = await apiHandler.get('/devices');
      runInAction(() => this.devices = data);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async loadDeviceById(deviceId) {
    this.setLoading(true);
    try {
      const device = await apiHandler.get(`/devices/${deviceId}`);
      runInAction(() => this.selectedDevice = device);
    } catch (error) {
      this.handleError(error);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }

  async loadDeviceLogs({ deviceId, startDate, endDate, interval, page = 1, limit = -1, sortBy = '', fields = '', search = '', searchField = 'name' }) {
    this.setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        startDate,
        endDate,
        deviceId,
        interval,
        page,
        limit,
        sortBy,
        fields,
        search,
        searchField
      });
      const endpoint = `/devices/logsByInterval?${queryParams.toString()}`;
      const response = await apiHandler.get(endpoint);

      runInAction(async () => {
        this.deviceLogs = response.results;
        this.pagination = {
          hasMore: response.hasMore,
          totalCount: response.totalCount,
          currentPage: response.currentPage,
          pageSize: response.pageSize,
          totalPages: response.totalPages
        };

        const totalLogs = response.totalCount;

        await this.aggregateLogsByValve(deviceId, startDate, endDate, (totalLogs / 2), response.results);

      });
      return;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async aggregateLogsByValve(deviceId, startDate, endDate, desiredPoints, existingLogs) {
    try {
      const queryParams = new URLSearchParams({
        deviceId,
        startDate: startDate,
        endDate: endDate,
        desiredPoints
      });
      const endpoint = `/devices/aggregatedLogs?${queryParams.toString()}`;
      const response = await apiHandler.get(endpoint);

      runInAction(() => {
        if (existingLogs && existingLogs.length > 0) {
          const averagedLogs = response.results;

          const firstLogTime = new Date(existingLogs[0].timestamp).getTime();
          const lastLogTime = new Date(existingLogs[existingLogs.length - 1].timestamp).getTime();
          const epsilon = (((lastLogTime - firstLogTime) / existingLogs.length) / 2) || 250; // 250ms is the default interval

          let combinedLogs = [...existingLogs];
          averagedLogs.forEach((log) => {
            let add = true;
            let avgTimestamp = new Date(log.timestamp).getTime();

            existingLogs.forEach((existingLog) => {
              let existingTimestamp = new Date(existingLog.timestamp).getTime();
              // If the timestamp is within epsilon of an existing log, don't add it
              if (Math.abs(avgTimestamp - existingTimestamp) < epsilon) {
                add = false;
              }
            });
            if (add) {
              combinedLogs.push(log);
            }
          });
          const sortedLogs = combinedLogs.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
          this.deviceLogs = sortedLogs;
        } else {
          this.deviceLogs = response.results;
        }
      });

      return this.deviceLogs;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getDeviceCSVLogs(deviceId, startTime, endTime) {
    this.setLoading(true);
    try {
      const filter = {
        timestamp: {
          $gte: new Date(startTime).toISOString(),
          $lte: new Date(endTime).toISOString()
        },
        device: deviceId
      };
      const response = await apiHandler.get(`/devices/exportLogsToCSV?filter=${JSON.stringify(filter)}`);
      return response;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async updateDevice(deviceId, deviceData) {
    this.setLoading(true);
    try {
      const updatedDevice = await apiHandler.put(`/devices/${deviceId}`, deviceData);
      runInAction(() => {
        this.selectedDevice = updatedDevice;
        this.devices = this.devices.map(device => device.id === deviceId ? updatedDevice : device);
      });
    } catch (error) {
      this.handleError(error);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }

  async createDevice(deviceData) {
    this.setLoading(true);
    try {
      const newDevice = await apiHandler.post('/devices', deviceData);

      runInAction(() => {
        this.devices.unshift(newDevice);
      });
    } catch (error) {
      this.handleError(error);
      throw error;
    } finally {
      this.setLoading(false);
    }

  };

  async deleteDevice(deviceId) {
    this.setLoading(true);
    try {
      await apiHandler.delete(`/devices/${deviceId}`);
      runInAction(() => {
        this.devices = this.devices.filter(device => device.id !== deviceId);
        this.selectedDevice = null;
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  setLoading(loading) {
    this.isLoading = loading;
  }

  handleError(error) {
    runInAction(() => this.error = error.message || 'An unexpected error occurred');
    console.error('DeviceStore Error:', this.error);
  }

  clearError() {
    this.error = null;
  }

  setSelectedDevice(device) {
    this.selectedDevice = device;
  }

  clearSelectedDevice() {
    this.selectedDevice = null;
  }

  composeDateFilter(startDate, endDate) {
    const filter = {};
    if (startDate) filter.$gte = new Date(startDate).toISOString();
    if (endDate) filter.$lte = new Date(endDate).toISOString();
    return filter;
  }
}

const deviceStore = new DeviceStore();
export default deviceStore;
